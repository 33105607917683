/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

*{
  font-family: "Open Sans", sans-serif;
}


/* AIAsistantCard.css */
.ai-assistant-card {
  border-radius: 8px;
  text-align: center;
}

.ai-assistant-image {
  margin: 0 auto;
  animation: float 4s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
}

.blur-button {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: none;
  color: white;
  transition: background 0.3s ease, transform 0.2s ease;
}

.blur-button:hover {
  background: rgba(255, 255, 255, 0.4);
}

.chat-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.ant-modal-body {
  padding: 0;
}

.ant-list {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Listeyi en alta hizalar */
}


.chat-question-container {
  display: flex;
  justify-content: end !important; /* Soruyu sağa hizalar */
  margin-bottom: 5px;
}

.chat-question {
  background-color: #e6f7ff;
  color: #000;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  text-align: right;
  word-wrap: break-word;
}

.chat-answer-container {
  display: flex;
  justify-content: flex-start; /* Cevabı sola hizalar */
  margin-bottom: 10px;
}

.chat-answer {
  background-color: #f6f6f6;
  color: #000;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
  text-align: left;
  word-wrap: break-word;
}
